
import { defineComponent, ref } from 'vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import { contactListsOptions } from '@/definitions/_general/_data/optionsList'
import { capitalize } from '@/services/utils'
import type { ListChip } from '@/definitions/shared/types'
import { getAssociateOptions } from '@/mock/associateCategories'

export default defineComponent({
  components: {
    TmFormLine,
  },
  setup() {
    const tagValue = ref([])
    const tags = ref(['tag1', 'tag2', 'tag3', 'tag4'])

    const lists = ref<ListChip[]>([contactListsOptions[0]])
    const editableBadges = ref([])
    const listsOptions = ref<ListChip[]>([...contactListsOptions])

    const recipients = ref([])
    const recipientsOptions = getAssociateOptions(['contacts', 'lists', 'segments'])
    const recipientsOptionsTransformed = ref(
      recipientsOptions
        .map((item: any) => {
          return {
            ...item,
            label: item?.name,
            icon: item?.icon === 'person' ? '' : item?.icon,
          }
        })
    )

    return {
      capitalize,
      tagValue,
      tags,
      editableBadges,
      lists,
      listsOptions,
      recipientsOptionsTransformed,
      recipients,
    }
  },
})
